export default {
  props: {
    pharmacy: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onPharmacySelect() {
      if (this.selected) {
        return;
      } else {
        this.$emit('select', this.pharmacy);
      }
    }
  }
};